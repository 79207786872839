module.exports = [{
      plugin: require('../node_modules/gatsby-theme-developer/gatsby-browser.js'),
      options: {"plugins":[],"title":"Ovidiu Bute (Ovi)","subtitle":"Tech Lead","description":"Ovi's personal blog and portfolio","siteUrl":"https://ovidiu.dev","defaultPreviewImage":"/favicon-32x32.png","author":{"name":"Ovidiu Bute (Ovi)","bio":"Tech Lead","avatar":"/avatar.jpeg","links":{"linkedin":"https://linkedin.com/in/ovidiubute","github":"https://github.com/ovidiubute","mail":"hello@ovidiu.dev","twitter":"","instagram":""}},"links":[{"title":"Home","href":"/"},{"title":"About me","href":"/about-me"},{"title":"Blog","href":"/blog"}],"articlesPerPage":4,"language":"en"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-JTC98N8R71"],"gtagConfig":{"optimize_id":"OPT_CONTAINER_ID","anonymize_ip":true,"cookie_expires":0},"pluginConfig":{"head":false,"respectDNT":true,"exclude":["/preview/**","/do-not-track/me/too/"],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0}},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
